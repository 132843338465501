<template>
  <div>
    <div style="padding-bottom:44px">
      <van-field label="型号" v-model="zrd004_step3.xingHao" :readonly="status!=0"></van-field>
      <van-field label="编号" v-model="zrd004_step3.biaoHao" :readonly="status!=0"></van-field>
      <van-field label="公司名称" center>
        <template #input>
          <el-autocomplete style="width:100%" :fetch-suggestions="querySearchCompanyAsync" @select="handleSelectCompany" v-model="zrd004_step3.companyName" value-key="dataText">
          </el-autocomplete>
        </template>
      </van-field>
      <van-field label="经销商" center>
        <template #input>
          <el-autocomplete style="width:100%" :fetch-suggestions="querySearchAgentAsync" @select="handleSelectAgent" v-model="zrd004_step3.agentName" value-key="contactName"></el-autocomplete>
        </template>
      </van-field>
      <van-field label=" 经销商电话" v-model="zrd004_step3.agentPhone" :readonly="status!=0">
      </van-field>
      <van-field label="日期" v-model="zrd004_step3.riQi" is-link readonly @click="popupVisible=status==0"></van-field>
      <van-field label="备注" v-model="zrd004_step3.remark" :readonly="status==1"></van-field>
    </div>

    <div class="footer">
      <van-button type="info" v-show="$parent.hasPower_Step3" round block :disabled="$parent.zrd004_SignNumber.status!=0" @click="save_ZRD004_Step3">保存</van-button>
      <van-button type="danger" v-if="$parent.zrd004_SignNumber.status==1" round block @click="$parent.cancel_end_SignNumber">取消结单</van-button>
      <van-button type="danger" v-else round block @click="$parent.end_SignNumber">结单</van-button>
    </div>
    <van-popup v-model="popupVisible" position="bottom">
      <van-datetime-picker type="year-month" title="选择年月" v-model="dtpValue" @cancel="popupVisible=false" @confirm="confirmRiQi"></van-datetime-picker>
    </van-popup>
  </div>

</template>

<script>
import Vue from "vue";
import Vant from "vant";
import Moment from "moment";
import "vant/lib/index.css";
Vue.use(Vant);
export default {
  props: ["signNumber"],
  data() {
    return {
      popupVisible: false,
      zrd004_step3: { agentPhone: "", companyId: null, agentId: null },
      dtpValue: new Date(),
      loading: false,
      status: 0,
      hasPower: false,
      hasJieDan: false,
    };
  },
  methods: {
    querySearchCompanyAsync(queryString, cb) {
      let that = this;
      that.axios
        .post("Base_SystemBaseData/SearchCompany", { onlyText: queryString })
        .then(function (response) {
          cb(response.data.data);
        });
    },
    handleSelectCompany(v) {
      let that = this;
      that.zrd004_step3.companyId = v.id;
    },
    querySearchAgentAsync(queryString, cb) {
      let that = this;
      that.axios
        .post("Base_Agent/SearchContactName", { onlyText: queryString })
        .then(function (response) {
          cb(response.data.data);
        });
    },
    handleSelectAgent(v) {
      let that = this;
      that.zrd004_step3.agentId = v.id;
      that.zrd004_step3.agentPhone = v.phone;
    },
    confirmRiQi(v) {
      let that = this;
      that.zrd004_step3.riQi = Moment(v).format("yyyy-MM");
      that.popupVisible = false;
    },
    getZRD004_Step3() {
      let that = this;
      that.axios
        .post("/ZRD004/GetZRD004_Step3", { onlyText: that.signNumber })
        .then(function (response) {
          that.zrd004_step3 = response.data.data;
          that.dtpValue = Moment(that.zrd004_step3.riQi).toDate();
          that.zrd004_step3.riQi = Moment(that.zrd004_step3.riQi).format(
            "yyyy-MM"
          );
        });
    },
    save_ZRD004_Step3() {
      let that = this;
      that.axios
        .post("ZRD004/UpdateZRD004_Step3", that.zrd004_step3)
        .then(function (response) {
          that.zrd004_step3 = response.data.data;
          that.zrd004_step3.riQi = Moment(that.zrd004_step3.riQi).format(
            "yyyy-MM"
          );
          if (response.data.code == 101) {
            that.$notify({ type: "success", message: response.data.msg });
          } else {
            that.$notify({ type: "warning", message: response.data.msg });
          }
        });
    },
  },
  mounted() {
    let that = this;
    that.getZRD004_Step3();
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
</style>