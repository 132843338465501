<template>
  <div>
    <van-cell title="按键测试">
      <template #right-icon>
        <van-checkbox v-model="zrd004_Step1.anJianCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="数码管测试">
      <template #right-icon>
        <van-checkbox v-model="zrd004_Step1.shuMaGuanCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="LED小灯测试">
      <template #right-icon>
        <van-checkbox v-model="zrd004_Step1.ledXiaoDengCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="照明输出">
      <template #right-icon>
        <van-checkbox v-model="zrd004_Step1.zhaoMingShuChu" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="电磁铁输出">
      <template #right-icon>
        <van-checkbox v-model="zrd004_Step1.dianCiTieShuChu" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-field label="备注" v-model="zrd004_Step1.remark"> </van-field>
    <div class="footer">
      <van-button type="info" v-show="$parent.hasPower_Step1" :disabled="$parent.zrd004_SignNumber.status!=0" round block @click="save_ZRD004_Step1">保存</van-button>
      <van-button type="danger" v-if="$parent.zrd004_SignNumber.status==2" round block @click="$parent.cancel_void_SignNumber()">取消作废</van-button>
      <van-button type="danger" v-else-if="$parent.zrd004_SignNumber.status!=1" round block @click="$parent.void_SignNumber()">作废</van-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["signNumber"],
  data() {
    return {
      zrd004_Step1: {},
    };
  },
  methods: {
    getZRD004_Step1() {
      let that = this;
      that.axios.post("/ZRD004/GetZRD004_Step1", { onlyText: that.signNumber }).then(function (response) {
        that.zrd004_Step1 = response.data.data;
      });
    },
    save_ZRD004_Step1() {
      let that = this;
      that.axios.post("ZRD004/UpdateZRD004_Step1", that.zrd004_Step1).then(function (response) {
        that.zrd004_Step1 = response.data.data;
        that.$notify({ type: "success", message: response.data.msg });
      });
    },
  },
  mounted() {
    let that = this;
    that.getZRD004_Step1();
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
</style>