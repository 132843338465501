<template>
  <div style="padding-bottom:44px">
    <van-field label="序列号" v-model="zrd004_Step2.serialNumber" placeholder="请输入序列号">
      <template #right-icon>
        <van-tag type="warning">{{jieMa}}</van-tag>
      </template>
    </van-field>
    <van-cell title="按键测试">
      <template #right-icon>
        <van-checkbox v-model="zrd004_Step2.anJianCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="数码管测试">
      <template #right-icon>
        <van-checkbox v-model="zrd004_Step2.shuMaGuanCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="LED小灯测试">
      <template #right-icon>
        <van-checkbox v-model="zrd004_Step2.ledXiaoDengCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="照明输出">
      <template #right-icon>
        <van-checkbox v-model="zrd004_Step2.zhaoMingShuChu" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="电磁铁输出">
      <template #right-icon>
        <van-checkbox v-model="zrd004_Step2.dianCiTieShuChu" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="尺检测">
      <template #right-icon>
        <van-checkbox v-model="zrd004_Step2.chiJianCe" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="97.5克配重检测">
      <template #right-icon>
        <van-checkbox v-model="zrd004_Step2.peiZhong975Ke" shape="square"></van-checkbox>
      </template>
    </van-cell>

    <van-field label="备注" v-model="zrd004_Step2.remark"> </van-field>
    <el-button type="primary" class="footer1" icon="el-icon-view" circle @click="handlePicClick"></el-button>
    <div class="footer">
      <van-button type="info" v-show="$parent.hasPower_Step2" round block :disabled="$parent.zrd004_SignNumber.status!=0" @click="handleSave">保存</van-button>
      <van-button type="danger" v-if="$parent.zrd004_SignNumber.status==2" round block @click="$parent.cancel_void_SignNumber()">取消作废</van-button>
      <van-button type="danger" v-else-if="$parent.zrd004_SignNumber.status!=1" round block @click="$parent.void_SignNumber()">作废</van-button>

    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  props: ["signNumber"],
  data() {
    return {
      zrd004_Step2: {},
      jieMa: "",
    };
  },
  methods: {
    handlePicClick() {
      ImagePreview(["./images/ZRD004_Produce.png"]);
    },
    getZRD004_Step2() {
      let that = this;
      that.axios.post("/ZRD004/GetZRD004_Step2", { onlyText: that.signNumber }).then(function (response) {
        that.zrd004_Step2 = response.data.data;
      });
    },
    handleSave() {
      let that = this;
      that.axios.all([that.save_ZRD004_Step2(), that.registerSerialNumber()]).then(
        that.axios.spread(function (response1, response2) {
          that.zrd004_Step2 = response1.data.data;
          if (response1.data.code == 201) {
            that.$notify({ type: "warning", message: response1.data.msg });
          } else if (response2.data.code == 201) {
            that.$notify({ type: "warning", message: response2.data.msg });
          } else {
            that.$notify({ type: "success", message: response1.data.msg });
          }
          if (response2.data.code == 101) {
            that.jieMa = response2.data.msg;
          }
        })
      );
    },
    save_ZRD004_Step2() {
      let that = this;
      return that.axios.post("ZRD004/UpdateZRD004_Step2", that.zrd004_Step2);
    },
    registerSerialNumber() {
      let that = this;
      return that.axios.post("Base_Device/Register", {
        productName: "数显针入度",
        productId: 1098,
        serialNumber: that.zrd004_Step2.serialNumber,
        deviceType: "ZRD004",
        companyName: "",
        ifPrint: false,
      });
    },
  },
  mounted() {
    let that = this;
    that.getZRD004_Step2();
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
.footer1 {
  position: fixed;
  left: 10px;
  bottom: 50px;
}
</style>